
import moment from "moment";
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
// import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
// import AddCustomerModal from "@/components/modals/forms/AddCustomerModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import conatctgroups from "@/core/data/contactgroup";
import { IContactGroup } from "@/core/data/contactgroup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default defineComponent({
  name: "customers-listing",
  components: {
    Datatable,
    // ExportCustomerModal,
    // AddCustomerModal,
    VPagination,
  },
  setup() {
    const store = useStore();
    const checkedContactGroup = ref([]);
    const showTable = ref(false);
    const limit = ref(10);
    const total_pages = ref(1);
    const tableHeader = ref([
      // {
      //   key: "checkbox",
      //   sortable: false,
      // },
      {
        name: "Group Name",
        key: "groupname",
        sortable: true,
      },
      {
        name: "Total Cotact",
        key: "total_contact",
        sortable: true,
      },
      {
        name: "Created Date",
        key: "createdAt",
        sortable: true,
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]);

    const tableData = ref<Array<IContactGroup>>([]);
    const initCustomers = ref<Array<IContactGroup>>([]);

    onMounted(() => {
      setCurrentPageBreadcrumbs("Contact Group", []);
      initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
      // console.log('tableData',tableData.value);
      getItem(1);
    });

    const getItem = (page) => {
      var request = {
        url: `/group?skip=${page}&limit=${limit.value}`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          tableData.value = data.data;
          initCustomers.value.splice(
            0,
            tableData.value.length,
            ...tableData.value
          );
          total_pages.value = Math.ceil(data.total / limit.value);
          showTable.value = true;
          // console.log('datata',tableData.value);
        }
      });
    };

    const deleteFewContactGroup = () => {
      checkedContactGroup.value.forEach((item) => {
        deleteContactGroup(item);
      });
      checkedContactGroup.value.length = 0;
    };

    const deleteContactGroup = (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          // for (let i = 0; i < tableData.value.length; i++) {
          //   if (tableData.value[i].id === id) {
          //     tableData.value.splice(i, 1);
          //   }
          // }
          var request = {
            url: `group/${id}`,
          };
          store.dispatch(Actions.DELETE, request).then((data) => {
            showTable.value = false;
            Swal.fire("Deleted!", "Group has been deleted.", "success");
            getItem(1);
          });
        }
      });
    };

    const downloadContact = (id) => {
      // var id = route.params.id;
      var request = {
        url: `/groupwise-conatct/${id}`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          downloadFileForContact(data.data, "ContactCsv");
        }
      });
    };

    const downloadFileForContact = (data, filename = "data") => {
      let csvData = ConvertToCSV(data, [
        "replay_status",
        "first_name",
        "last_name",
        "property_address",
        "mobileno",
        "city",
        "zip",
      ]);
      // console.log(csvData)
      let blob = new Blob(["\ufeff" + csvData], {
        type: "text/csv;charset=utf-8;",
      });
      let dwldLink = document.createElement("a");
      let url = URL.createObjectURL(blob);
      let isSafariBrowser =
        navigator.userAgent.indexOf("Safari") != -1 &&
        navigator.userAgent.indexOf("Chrome") == -1;
      if (isSafariBrowser) {
        //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
      }
      dwldLink.setAttribute("href", url);
      dwldLink.setAttribute("download", filename + ".csv");
      dwldLink.style.visibility = "hidden";
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
    };

    const ConvertToCSV = (objArray, headerList) => {
      let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
      let str = "";
      let row = "";

      for (let index in headerList) {
        row += headerList[index] + ",";
      }
      row = row.slice(0, -1);
      str += row + "\r\n";
      for (let i = 0; i < array.length; i++) {
        let line: any = "";
        for (let j = 0; j < headerList.length; j++) {
          var data;
          switch (headerList[j]) {
            case "replay_status":
              data = array[i] && array[i].replay_status == 'true'
                ? 'yes'
                : "no";
                break;
            default:
                data = array[i][headerList[j]]
                ? array[i][headerList[j]].toString().replace(",", " ")
                : "0";
          }
          data = data
            .toString()
            .replace(/,/g, " ")
            .replace(/\r\n/g, " ")
            .replace(/\n/g, " ");

          if (line == "") {
            line = data;
          } else {
            line += "," + data;
          }
        }
        // return str;
        // for (let index in headerList) {
        //   let head = headerList[index];
        //   var data = array[i][head]
        //     ? array[i][head].toString().replace(",", " ")
        //     : "0";
        //   if (line == "") {
        //     line = data;
        //   } else {
        //     line += "," + data;
        //   }
        // }
        str += line + "\r\n";
      }
      return str;
    };

    const search = ref<string>("");
    const searchItems = () => {
      tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
      if (search.value !== "") {
        let results: Array<IContactGroup> = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], search.value)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
      }
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    const dateTime = (value) => {
      return moment(value).format("YYYY-MM-DD");
    };

    const updateHandler = (value) => {
      showTable.value = false;
      getItem(value);
    };
    const page = ref(1);
    return {
      tableData,
      tableHeader,
      deleteFewContactGroup,
      search,
      searchItems,
      checkedContactGroup,
      deleteContactGroup,
      showTable,
      getItem,
      dateTime,
      downloadContact,
      downloadFileForContact,
      ConvertToCSV,
      updateHandler,
      total_pages,
      page
    };
  },
});
